import React from 'react';
import {graphql} from 'gatsby';
import styled from 'styled-components';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Tabs from '../components/tabs';
import theme from '../utils/theme';
import alert from '../../static/images/alert-triangle.svg';

const StaticContent = styled.div`
  padding: ${({smallPadding}) => (smallPadding ? '0 80px' : '100px 80px 0')};
  margin-bottom: ${({bottomMargin}) => (bottomMargin ? '80px' : 0)};

  h1 {
    margin-bottom: 16px;
  }

  h2 {
    margin: 50px 0 22px;
  }

  h3 {
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-weight: bold;
    line-height: 26px;
    margin-bottom: 28px;
  }

  p {
    font-size: 16px;
    line-height: 26px;
    max-width: 640px;
    margin-bottom: 42px;
  }

  ul {
    margin-bottom: 22px;
  }

  ul li {
    line-height: 26px;
    font-size: 16px;
    &:before {
      content: '\\2022';
      color: ${theme.primaryLight};
      display: inline-block;
      width: 1em;
    }
  }

  td a,
  p a {
    font-size: inherit;
    line-height: inherit;
    text-decoration: none;
    color: ${theme.primaryLight};
    transition: 100ms;
  }
  td a:hover,
  p a:hover {
    color: ${theme.primaryHover};
  }
  td a:active,
  p a:active {
    color: ${theme.primaryClick};
  }

  table {
    max-width: 902px;
    margin-bottom: 44px;
  }

  th {
    padding: 22px 16px;
    background: rgba(155, 189, 202, 0.3);
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.58px;
    min-width: 144px;
    text-align: left;
    border-right: 1px solid rgba(16, 70, 56, 0.16);
  }

  th:first-child {
    border-radius: 5px 0 0 0;
  }
  th:last-child {
    border-radius: 0 5px 0 0;
  }

  td {
    border-right: 1px solid rgba(16, 70, 56, 0.16);
    padding: 12px 12px 30px;
    font-size: 14px;
    line-height: 18px;
    position: relative;
  }

  td code {
    padding: 2px 4px !important;
    display: inline !important;
  }

  th:last-child,
  tr td:last-child {
    border-right: none;
  }

  tr:nth-child(even) {
    background-color: rgba(155, 189, 202, 0.1);
  }

  .language-text {
    max-width: 887px;
    font-size: 14px;
    border-radius: 5px;
    padding: 2px 4px;
    background: rgba(155, 189, 202, 0.3) !important;
    color: ${theme.textBlack} !important;
    line-height: 24px;
    word-break: break-all;
  }

  notice {
    display: inline-block;
    background: rgba(234, 204, 44, 0.05);
    border: 1px solid ${theme.seconadryYellow};
    border-radius: 5px;
    padding: 24px 28px 25px 32px;

    :before {
      content: url(${alert});
      margin-right: 12px;
      vertical-align: middle;
    }
  }

  @media (max-width: 768px) {
    padding: ${({smallPadding}) => (smallPadding ? '0' : '100px 0 0')};
    margin: 0 auto;
    max-width: 280px;
  }
`;

export default ({data}) => {
  const {
    markdownRemark: {
      html,
      frontmatter: {title},
    },
  } = data;
  const [tabs, setTabs] = React.useState(null);
  const [tabHeader, setTabHeader] = React.useState(null);
  const [contentAfterTabs, setContentAfterTabs] = React.useState(null);
  const getNextSiblings = element => {
    const siblings = [];
    const pushNext = sibling => {
      siblings.push({
        element: sibling,
        content: sibling.outerHTML,
      });
      if (sibling.nextElementSibling) {
        pushNext(sibling.nextElementSibling);
      }
    };
    if (element) {
      pushNext(element);
    }

    return siblings;
  };

  React.useEffect(() => {
    const wrapper = document.getElementById('static-content');
    const header = wrapper.querySelector('tab-header');
    let endContent = [];
    const tabElements = Array.from(wrapper.querySelectorAll('tab')).map((tab, index) => {
      if (index === wrapper.querySelectorAll('tab').length - 1) {
        endContent = getNextSiblings(tab.nextElementSibling);
      }
      return {
        name: tab.getAttribute('name'),
        element: tab,
      };
    });
    if (!tabs && tabElements.length > 0) {
      tabElements.forEach(tab => wrapper.removeChild(tab.element));
      setTabs(tabElements);
    }
    if (header && !tabHeader) {
      setTabHeader(header.children[0].innerHTML);
      wrapper.removeChild(header);
      wrapper.appendChild(header);
    }
    if (!contentAfterTabs && endContent.length > 0) {
      setContentAfterTabs(endContent.map(child => child.content).join(''));
      endContent.forEach(child => wrapper.removeChild(child.element));
    }
  });

  return (
    <Layout>
      <SEO title={title} />
      <StaticContent
        bottomMargin={!tabs}
        id="static-content"
        dangerouslySetInnerHTML={{__html: html}}
      />
      {tabs && <Tabs tabs={tabs} />}
      <StaticContent smallPadding dangerouslySetInnerHTML={{__html: contentAfterTabs}} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: {path: {eq: $path}}) {
      html
      frontmatter {
        title
      }
    }
  }
`;
